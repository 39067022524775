
import { defineComponent } from "vue";
import { ElLoading } from "element-plus";
import { ElMessage } from "element-plus";
import helpers from "@/helpers/global";

export default defineComponent({
  name: "CreateAccount",
  created() {
    if (localStorage.getItem("userData")) {
      this.$router.replace("/");
      return;
    }
    this.$store.dispatch("forceReady", null, { root: true });
  },
  watch: {
    error(value) {
      console.error(value);
    },
    userLogin(value) {
      if (value) {
        this.$router.push("/");
      }
    },
    loadingStatus(value) {
      const loading = ElLoading.service({
        lock: true,
        text: "Login..",
        background: "#ffffff90",
      });
      if (!value) {
        loading.close();
      }
    },
  },
  computed: {
    userLogin(): any {
      return this.$store.getters.getLoginStatus;
    },
  },
  data() {
    return {
      validateOtp: false,
      flowPage: "login",
      otp: {
        one: "",
        two: "",
        three: "",
        four: "",
        five: "",
        six: "",
      },
      otpWarning: false,
      otpWarningText: "",
    };
  },
  mounted() {
    if (this.userLogin) {
      this.$router.push("/");
    }
    if (this.$route.query.flow) {
      this.flowPage = this.$route.query.flow as string;
    } else {
      this.flowPage = this.$route.path == "/signup/verify" ? "signup" : "login";
    }
  },
  methods: {
    async continueFunc() {
      this.otpWarning = false;
      if (
        ("" + this.otp.one).length &&
        ("" + this.otp.two).length &&
        ("" + this.otp.three).length &&
        ("" + this.otp.four).length &&
        ("" + this.otp.five).length &&
        ("" + this.otp.six).length
      ) {
        const otp = this.otp;
        const pincode = `${otp.one}${otp.two}${otp.three}${otp.four}${otp.five}${otp.six}`;
        const email =
          this.$store.getters["auth/email"] || this.$route.query.email;

        if (this.flowPage == "login") {
          this.$store.commit("loadingStatus", true);
          const result = await this.$store.dispatch(
            "auth/loginByOtp",
            { email: email, pincode: pincode },
            { root: true }
          );
          this.$store.commit("loadingStatus", false);
          if (!result) {
            ElMessage.error((this as any).$t("popup.message.message_7e"));
          }
        } else {
          this.$store.commit("loadingStatus", true);
          await this.$store.dispatch(
            "auth/verifySignupOtp",
            { email: email, pincode: pincode },
            { root: true }
          );
          this.$store.commit("loadingStatus", false);
          if (this.$store.getters["auth/error"]) {
            ElMessage.error((this as any).$t("popup.message.message_7e"));
            return;
          }

          const referralCode = helpers.getReferralCode();
          if (referralCode) {
            this.$router.push("/signup/info?" + referralCode);
          } else {
            this.$router.push({ name: "SignupInfo" });
          }
        }
        // if (!result) {
        //   // @todo error handler
        //   ElMessage.error((this as any).$t("popup.message.message_7e"));
        // }
        // this.$router.push("/");
      } else {
        ElMessage.error((this as any).$t("popup.message.message_7e"));
        this.otpWarning = true;
      }
    },
    checkedOTPOne(e: any) {
      const value = e.target.value;
      if (value.length == 6) {
        this.otp.one = value.slice(0, 1);
        this.otp.two = value.slice(1, 2);
        this.otp.three = value.slice(2, 3);
        this.otp.four = value.slice(3, 4);
        this.otp.five = value.slice(4, 5);
        this.otp.six = value.slice(5, 6);
        const input = this.$refs["input_otp_six"] as any;
        input.select();
      } else if (value.length > 0) {
        this.otp.one = value.slice(0, 1);
        const input = this.$refs["input_otp_two"] as any;
        input.select();
      }
    },
    checkedOTPTwo() {
      const two = this.otp.two + "";
      if (!two.length) {
        this.otp.two = "";
        const input = this.$refs["input_otp_one"] as any;
        input.select();
      } else {
        const input = this.$refs["input_otp_three"] as any;
        input.select();
      }
    },
    checkedOTPThree() {
      const three = this.otp.three + "";
      if (!three.length) {
        this.otp.three = "";
        const input = this.$refs["input_otp_two"] as any;
        input.select();
      } else {
        const input = this.$refs["input_otp_four"] as any;
        input.select();
      }
    },
    checkedOTPFour() {
      const four = this.otp.four + "";
      if (!four.length) {
        this.otp.four = "";
        const input = this.$refs["input_otp_three"] as any;
        input.select();
      } else {
        const input = this.$refs["input_otp_five"] as any;
        input.select();
      }
    },
    checkedOTPFive() {
      const five = this.otp.five + "";
      if (!five.length) {
        this.otp.five = "";
        const input = this.$refs["input_otp_four"] as any;
        input.select();
      } else {
        const input = this.$refs["input_otp_six"] as any;
        input.select();
      }
    },
    checkedOTPSix() {
      const six = this.otp.six + "";
      if (!six.length) {
        this.otp.six = "";
        const input = this.$refs["input_otp_five"] as any;
        input.select();
      } else if (six.length > 1) {
        this.otp.six = six.slice(0, 1);
      }
    },
  },
});
